<template>
  <div v-if="dialog && dataConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition"  max-width="60%" style="z-index: 9999 !important">
      <v-card>
        <div class="modal-header">
          <div class="row">
            <h5 class="modal-title col-6" id="formModal">
              {{ $t("title.user_limit_betting_kh") }}
            </h5>
            <!-- <div class="col-6" style="text-align: end;">
              <button
                class="btn btn-primary"
                :disabled="loading"
                text
                @click="validate"
              >
                {{ $t("button.save") }}
              </button>
            </div> -->
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form
              v-if="dataConfig"
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-tabs
                background-color="blue-grey lighten-5 accent-4"
                center-active
              >
                <v-tab v-for="(indexK, index3) in dataConfig.objectKh" :key="index3">
                  <span v-if=" indexK.channel_type == 1">CO1</span>
                  <span v-if=" indexK.channel_type == 2">CO2</span>
                  <span v-if=" indexK.channel_type == 3">CO3</span>
                  <span v-if=" indexK.channel_type == 4">CO4</span>
                  <span v-if=" indexK.channel_type == 5">KL1</span>
                  <span v-if=" indexK.channel_type == 6">BA1</span>
                  <span v-if=" indexK.channel_type == 7">BA2</span>
                  <span v-if=" indexK.channel_type == 8">TD1</span>
                  <span v-if=" indexK.channel_type == 9">TD2</span>
                  <span v-if=" indexK.channel_type == 10">LO1</span>
                  <span v-if=" indexK.channel_type == 11">YU1</span>
                  <span v-if=" indexK.channel_type == 12">AP1</span>
                  <span v-if=" indexK.channel_type == 13">FT1</span>
                  <span v-if=" indexK.channel_type == 14">TS1</span>
                  <span v-if=" indexK.channel_type == 15">CO5</span>
                  <span v-if=" indexK.channel_type == 16">CO6</span>
                  <span v-if=" indexK.channel_type == 17">AP2</span>
                </v-tab>

                  <v-tab-item v-for="(indexOk, index4) in dataConfig.objectKh" :key="index4">
                  <v-card flat>
                    <v-card-text>
                        <v-alert text outlined color="deep-orange"> លុយ ខ្មែរ </v-alert>

                        <span class="text"> កំណត់ចំនួនដងនៃការចាក់ </span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentKh[indexOk.channel_type-1].betting_frequency
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.betting_frequency"
                          type="number"
                        />

                        <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentKh[indexOk.channel_type-1].total_bet_win_a_day
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.total_bet_win_a_day"
                          type="number"
                        />

                        <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentKh[indexOk.channel_type-1].total_bet_a_fight
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.total_bet_a_fight"
                          type="number"
                        />
                        <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">
                          {{
                            indexOk.currency_type == 1
                              ? 1
                              : indexOk.currency_type == 2
                              ? currencyFormatKh(dataConfig.objectParentKh[indexOk.channel_type-1].amount_min)
                              : indexOk.currency_type == 4
                              ? currencyFormatKh(dataConfig.objectParentKh[indexOk.channel_type-1].amount_min)
                              : "loading.."
                          }}</span
                        >
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.amount_min"
                          type="number"
                        />
                        <span class="text">ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">
                          {{
                            currencyFormatKh(dataConfig.objectParentKh[indexOk.channel_type-1].amount_max)
                          }}</span
                        >
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.amount_max"
                          type="number"
                        />
                    
                    </v-card-text>
                  </v-card>
                  <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(indexOk.channel_type)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
                </v-tab-item>
            </v-tabs>
          </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: [],
    limitConfig: [],
    // dataConfig: {
    //   type: Object,
    //   default: Object,
    // },
    // limitConfig: {
    //   type: Object,
    //   default: Object,
    // },
  },
  watch: {
    dataConfig: function (newV) {
      // this.correctSelectChanel(newV.objectUsd);
      this.correctSelectChanelKh(newV.objectKh);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      itemChannelk1: [
        {
          id: 10,
          name: "CO1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelk2: [
        {
          id: 20,
          name: "CO2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelk3: [
        {
          id: 30,
          name: "CO3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelk4: [
        {
          id: 40,
          name: "CO4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],
      itemChannelk5: [
        {
          id: 50,
          name: "KL1",
          children: [
            { id: "51", name: "Default", channel_type: 5, vip_option: 1 },
            { id: "52", name: "VIP 1", channel_type: 5, vip_option: 2 },
            { id: "53", name: " VIP 2", channel_type: 5, vip_option: 3 },
          ],
        },
      ],
      itemChannelk6: [
        {
          id: 60,
          name: "BA1",
          children: [
            { id: "61", name: "Default", channel_type: 6, vip_option: 1 },
            { id: "62", name: "VIP 1", channel_type: 6, vip_option: 2 },
            { id: "63", name: " VIP 2", channel_type: 6, vip_option: 3 },
          ],
        },
      ],
      itemChannelk7: [
        {
          id: 70,
          name: "BA2",
          children: [
            { id: "71", name: "Default", channel_type: 7, vip_option: 1 },
            { id: "72", name: "VIP 1", channel_type: 7, vip_option: 2 },
            { id: "73", name: " VIP 2", channel_type: 7, vip_option: 3 },
          ],
        },
      ],
      itemChannelk8: [
        {
          id: 80,
          name: "TD1",
          children: [
            { id: "81", name: "Default", channel_type: 8, vip_option: 1 },
            { id: "82", name: "VIP 1", channel_type: 8, vip_option: 2 },
            { id: "83", name: " VIP 2", channel_type: 8, vip_option: 3 },
          ],
        },
      ],
      itemChannelk9: [
        {
          id: 90,
          name: "TD2",
          children: [
            { id: "91", name: "Default", channel_type: 9, vip_option: 1 },
            { id: "92", name: "VIP 1", channel_type: 9, vip_option: 2 },
            { id: "93", name: " VIP 2", channel_type: 9, vip_option: 3 },
          ],
        },
      ],
      itemChannelk10: [
        {
          id: 100,
          name: "LO1",
          children: [
            { id: "101", name: "Default", channel_type: 10, vip_option: 1 },
            { id: "102", name: "VIP 1", channel_type: 10, vip_option: 2 },
            { id: "103", name: " VIP 2", channel_type: 10, vip_option: 3 },
          ],
        },
      ],
      itemChannelk11: [
        {
          id: 110,
          name: "YU1",
          children: [
            { id: "111", name: "Default", channel_type: 11, vip_option: 1 },
            { id: "112", name: "VIP 1", channel_type: 11, vip_option: 2 },
            { id: "113", name: " VIP 2", channel_type: 11, vip_option: 3 },
          ],
        },
      ],
      itemChannelk12: [
        {
          id: 120,
          name: "AP1",
          children: [
            { id: "121", name: "Default", channel_type: 12, vip_option: 1 },
            { id: "122", name: "VIP 1", channel_type: 12, vip_option: 2 },
            { id: "123", name: " VIP 2", channel_type: 12, vip_option: 3 },
          ],
        },
      ],

      selectionChannelk1: [],
      selectedChannelk1: [],
      selectionChannelk2: [],
      selectedChannelk2: [],
      selectionChannelk3: [],
      selectedChannelk3: [],
      selectionChannelk4: [],
      selectedChannelk4: [],
      selectionChannelk5: [],
      selectedChannelk5: [],
      selectionChannelk6: [],
      selectedChannelk6: [],
      selectionChannelk7: [],
      selectedChannelk7: [],
      selectionChannelk8: [],
      selectedChannelk8: [],
      selectionChannelk9: [],
      selectedChannelk9: [],
      selectionChannelk10: [],
      selectedChannelk10: [],
      selectionChannelk11: [],
      selectedChannelk11: [],
      selectionChannelk12: [],
      selectedChannelk12: [],
      selectionChannelk: [],
    };
  },

  methods: {
    validate(channel_type) {
      if (this.$refs.form.validate()) {
      
        this.$emit("submit",channel_type);
        // for kh
        this.selectionChannelk = [];
        this.selectionChannelk1 = [];
        this.selectedChannelk1 = [];
        this.selectionChannelk2 = [];
        this.selectedChannelk2 = [];
        this.selectionChannelk3 = [];
        this.selectedChannelk3 = [];
        this.selectionChannelk4 = [];
        this.selectedChannelk4 = [];
        this.selectionChannelk5 = [];
        this.selectedChannelk5 = [];
        this.selectionChannelk6 = [];
        this.selectedChannelk6 = [];
        this.selectionChannelk7 = [];
        this.selectedChannelk7 = [];
        this.selectionChannelk8 = [];
        this.selectedChannelk8 = [];
        this.selectionChannelk9 = [];
        this.selectedChannelk9 = [];
        this.selectionChannelk10 = [];
        this.selectedChannelk10 = [];
        this.selectionChannelk11 = [];
        this.selectedChannelk11 = [];
        this.selectionChannelk12 = [];
        this.selectedChannelk12 = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanelKh(item) {
      for (let t in item) {
        if (
          item[t].channel_type == 1 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk1.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 2 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk2.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 3 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk3.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 4 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk4.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type ==  5&&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk5.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 6 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk6.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 7 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk7.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 8 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk8.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 9 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk9.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 10 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk10.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 11 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk11.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 12 &&
          item[t].currency_type == 2
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelk12.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChannelk1 = this.selectionChannelk1;
      this.selectedChannelk2 = this.selectionChannelk2;
      this.selectedChannelk3 = this.selectionChannelk3;
      this.selectedChannelk4 = this.selectionChannelk4;
      this.selectedChannelk5 = this.selectionChannelk5;
      this.selectedChannelk6 = this.selectionChannelk6;
      this.selectedChannelk7 = this.selectionChannelk7;
      this.selectedChannelk8 = this.selectionChannelk8;
      this.selectedChannelk9 = this.selectionChannelk9;
      this.selectedChannelk10 = this.selectionChannelk10;
      this.selectedChannelk11 = this.selectionChannelk11;
      this.selectedChannelk12 = this.selectionChannelk12;
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
